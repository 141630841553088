export const PAGE_TITLE = 'Brands';
export const PAGE_SINGLE_TITLE = 'Brand';
export const LINK_URL = 'brands';
export const inputFields = {
  name: {
    type: 'string',
    required: true,
    title: 'Name',
    inputType: 'text',
  },
  logo: {
    type: 'file',
    required: true,
    title: 'Logo',
    inputType: 'text',
  },
};
export const initialValues = {
  name: '',
};

export const view_all_table = [
  { name: 'Name', value: 'name' },
  { name: 'Logo', value: 'logo', image: true },
];

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
];
