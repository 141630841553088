import React from 'react';
import BreadCrumb from '../../components/template/BreadCrumb';
import Header from '../../components/template/Header';
import FilterDateComponent from '../../components/common/FilterDateComponent';
import moment from 'moment';

function Dashboard() {
  return (
    <div className='pace-done'>
      <Header />
      <div className='content-wrapper'>
        <FilterDateComponent link='/dashboard' />
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-9'></div>
          <div className='col-md-3'>
            <div className='card'>
              <div className='card-header'>
                <h4 className='card-title'> Notification </h4>
              </div>
              <div className='card-body'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
