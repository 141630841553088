import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addWebpage,
  getWebpages,
  getWebpage,
  editWebpage,
  deleteWebpage,
  getAllWebpages,
} from '../../store/actions/webpage_action';
import _debounce from 'lodash/debounce';
import { useAllLocations } from './UseLocation';
import { useAllSuccessStorys } from './UseSuccessStory';

// Get All Data
export const useAllWebpages = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.webpage);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteWebpage(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getWebpages({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleWebpage = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.webpage);
  useEffect(() => {
    dispatch(getWebpage(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateWebpage = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.webpage);
  const addData = async (data) => {
    await dispatch(addWebpage(data));
  };
  return [data, addData];
};
export const useUpdateWebpage = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.webpage);
  const updateData = async (id, data) => {
    await dispatch(editWebpage(id, data));
  };
  return [updateData];
};

export const useSelectAllWebpage = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState('');
  const [value, setValue] = useState('');
  const data = useSelector((state) => state.webpage);
  useEffect(() => {
    dispatch(getAllWebpages({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  const [location_data] = useAllLocations();
  const [success_stories_data] = useAllSuccessStorys();

  const [dropdownOptions, setDropdownOptions] = useState({});
  useEffect(() => {
    if (location_data && location_data.locations) {
      const newData = location_data.locations.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, location: newData });
    }
  }, [location_data]);
  useEffect(() => {
    if (success_stories_data && success_stories_data.successstorys) {
      const newData = success_stories_data.successstorys.map((item) => {
        return { label: item.label, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, success_stories: newData });
    }
  }, [success_stories_data]);

  return [
    dropdownOptions,
    // setClientSearchField, setClientSearchValue
  ];
};
