import React, { useEffect } from 'react';
import './assets/css/bootstrap.min.css';
import './assets/css/app.min.css';
import './assets/css/custom.css';
import './assets/css/wysiwyg.css';
import './assets/css/responsive.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import { loadUser } from './store/actions/auth';
import setAuthToken from './domain/setAuthToken';
import BeforeLoginRoutes from './shared/Routes/BeforeLoginRoutes';
import PrivateRoutes from './shared/Routes/PrivateRoutes';

import PageNotFound from './containers/notfound/PageNotFound';
import Login from './containers/login/Login';
import Dashboard from './containers/dashboard/Dashboard';
import Profile from './containers/profile/Profile';

import AddLocation from './containers/locations/AddLocation';
import AllLocations from './containers/locations/AllLocations';
import ViewLocation from './containers/locations/ViewLocation';
import EditLocation from './containers/locations/EditLocation';

import AddBrand from './containers/brands/AddBrand';
import AllBrands from './containers/brands/AllBrands';
import ViewBrand from './containers/brands/ViewBrand';
import EditBrand from './containers/brands/EditBrand';

import AddSuccessStory from './containers/successstorys/AddSuccessStory';
import AllSuccessStorys from './containers/successstorys/AllSuccessStorys';
import ViewSuccessStory from './containers/successstorys/ViewSuccessStory';
import EditSuccessStory from './containers/successstorys/EditSuccessStory';

import AddWebpage from './containers/webpages/AddWebpage';
import AllWebpages from './containers/webpages/AllWebpages';
import ViewWebpage from './containers/webpages/ViewWebpage';
import EditWebpage from './containers/webpages/EditWebpage';
import DuplicateWebpage from './containers/webpages/DuplicateWebpage';

function App() {
  useEffect(() => {
    //First we have to bring(get that) token, which is saved in local storage
    const token = localStorage.getItem('token');
    //then we will pass that token in setAuthToken method
    setAuthToken(token);
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <BeforeLoginRoutes exact path='/' component={Login} />
          <PrivateRoutes exact path='/dashboard' component={Dashboard} />
          <PrivateRoutes exact path='/profile' component={Profile} />

          <PrivateRoutes exact path='/locations' component={AllLocations} />
          <PrivateRoutes exact path='/locations/add' component={AddLocation} />
          <PrivateRoutes
            exact
            path='/locations/:id/view'
            component={ViewLocation}
          />
          <PrivateRoutes
            exact
            path='/locations/:id/edit'
            component={EditLocation}
          />

          <PrivateRoutes exact path='/brands' component={AllBrands} />
          <PrivateRoutes exact path='/brands/add' component={AddBrand} />
          <PrivateRoutes exact path='/brands/:id/view' component={ViewBrand} />
          <PrivateRoutes exact path='/brands/:id/edit' component={EditBrand} />

          <PrivateRoutes
            exact
            path='/success-stories'
            component={AllSuccessStorys}
          />
          <PrivateRoutes
            exact
            path='/success-stories/add'
            component={AddSuccessStory}
          />
          <PrivateRoutes
            exact
            path='/success-stories/:id/view'
            component={ViewSuccessStory}
          />
          <PrivateRoutes
            exact
            path='/success-stories/:id/edit'
            component={EditSuccessStory}
          />

          <PrivateRoutes exact path='/webpages' component={AllWebpages} />
          <PrivateRoutes exact path='/webpages/add' component={AddWebpage} />
          <PrivateRoutes
            exact
            path='/webpages/:id/view'
            component={ViewWebpage}
          />
          <PrivateRoutes
            exact
            path='/webpages/:id/edit'
            component={EditWebpage}
          />
          <PrivateRoutes
            exact
            path='/webpages/:id/duplicate'
            component={DuplicateWebpage}
          />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
