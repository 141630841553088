export const GET_SUCCESSSTORYS_STATED = "GET_SUCCESSSTORYS_STATED";
export const GET_SUCCESSSTORYS = "GET_SUCCESSSTORYS";
export const GET_SUCCESSSTORYS_ENDED = "GET_SUCCESSSTORYS_ENDED";
export const ADD_SUCCESSSTORY_STATED = "ADD_SUCCESSSTORY_STARTED";
export const ADD_SUCCESSSTORY = "ADD_SUCCESSSTORY";
export const ADD_SUCCESSSTORY_ENDED = "ADD_SUCCESSSTORY_ENDED";
export const EDIT_SUCCESSSTORY_STATED = "EDIT_SUCCESSSTORY_STATED";
export const EDIT_SUCCESSSTORY = "EDIT_SUCCESSSTORY";
export const EDIT_SUCCESSSTORY_ENDED = "EDIT_SUCCESSSTORY_ENDED";
export const GET_SUCCESSSTORY = "GET_SUCCESSSTORY";
export const GET_SUCCESSSTORY_STATED = "GET_SUCCESSSTORY_STATED";
export const GET_SUCCESSSTORY_ENDED = "GET_SUCCESSSTORY_ENDED";
export const RESET_SUCCESSSTORY = "RESET_SUCCESSSTORY";
export const ERROR_SUCCESSSTORY = "ERROR_SUCCESSSTORY";
export const GET_ALL_SUCCESSSTORYS_STATED = "GET_ALL_SUCCESSSTORYS_STATED";
export const GET_ALL_SUCCESSSTORYS = "GET_ALL_SUCCESSSTORYS";
export const GET_ALL_SUCCESSSTORYS_ENDED = "GET_ALL_SUCCESSSTORYS_ENDED";
