import {
  GET_LOCATIONS_STATED,
  GET_LOCATIONS,
  GET_LOCATIONS_ENDED,
  ADD_LOCATION_STATED,
  ADD_LOCATION,
  ADD_LOCATION_ENDED,
  EDIT_LOCATION_STATED,
  EDIT_LOCATION,
  EDIT_LOCATION_ENDED,
  GET_LOCATION_STATED,
  GET_LOCATION,
  GET_LOCATION_ENDED,
  GET_ALL_LOCATIONS_STATED,
  GET_ALL_LOCATIONS,
  GET_ALL_LOCATIONS_ENDED,
} from '../types/location_type';

const initialState = {
  locations_loading: true,
  locations: null,
  page: null,
  pages: null,
  total_locations: 0,

  location: null,
  location_loading: null,

  loading: true,

  location_message: null,
  all_locations: null,
  all_locations_loading: null,
  add_location_loading: true,
  edit_location_loading: true,
};

export const location_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LOCATIONS_STATED:
      return {
        ...state,
        locations: null,
        pages: null,
        page: null,
        total_locations: 0,
        locations_loading: true,
      };
    case GET_LOCATIONS:
      return {
        ...state,
        locations: payload.locations,
        pages: payload.pages,
        page: payload.page,
        total_locations: payload.total_locations,
      };
    case GET_LOCATIONS_ENDED:
      return {
        ...state,
        locations_loading: false,
      };
    case GET_ALL_LOCATIONS_STATED:
      return {
        ...state,
        all_locations_loading: true,
        all_locations: null,
      };
    case GET_ALL_LOCATIONS:
      return {
        ...state,
        all_locations: payload,
      };
    case GET_ALL_LOCATIONS_ENDED:
      return {
        ...state,
        all_locations_loading: false,
      };

    case ADD_LOCATION_STATED:
      return {
        ...state,
        location_message: null,
        add_location_loading: true,
      };
    case ADD_LOCATION:
      return {
        ...state,
        location_message: payload,
      };
    case ADD_LOCATION_ENDED:
      return {
        ...state,
        add_location_loading: false,
      };
    case GET_LOCATION_STATED:
      return {
        ...state,
        location: null,
        location_loading: true,
      };
    case GET_LOCATION:
      return {
        ...state,
        location: payload,
      };
    case GET_LOCATION_ENDED:
      return {
        ...state,
        location_loading: false,
      };
    case EDIT_LOCATION_STATED:
      return {
        ...state,
        location_message: null,
        edit_location_loading: true,
      };
    case EDIT_LOCATION:
      return {
        ...state,
        location_message: payload,
      };
    case EDIT_LOCATION_ENDED:
      return {
        ...state,
        edit_location_loading: false,
      };

    default:
      return state;
  }
};
