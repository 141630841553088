import {
  GET_SUCCESSSTORYS_STATED,
  GET_SUCCESSSTORYS,
  GET_SUCCESSSTORYS_ENDED,
  ADD_SUCCESSSTORY_STATED,
  ADD_SUCCESSSTORY,
  ADD_SUCCESSSTORY_ENDED,
  EDIT_SUCCESSSTORY_STATED,
  EDIT_SUCCESSSTORY,
  EDIT_SUCCESSSTORY_ENDED,
  GET_SUCCESSSTORY_STATED,
  GET_SUCCESSSTORY,
  GET_SUCCESSSTORY_ENDED,
  GET_ALL_SUCCESSSTORYS_STATED,
  GET_ALL_SUCCESSSTORYS,
  GET_ALL_SUCCESSSTORYS_ENDED,
} from '../types/successstory_type';

const initialState = {
  successstorys_loading: true,
  successstorys: null,
  page: null,
  pages: null,
  total_successstorys: 0,

  successstory: null,
  successstory_loading: null,

  loading: true,

  successstory_message: null,
  all_successstorys: null,
  all_successstorys_loading: null,
  add_successstory_loading: true,
  edit_successstory_loading: true,
};

export const successstory_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SUCCESSSTORYS_STATED:
      return {
        ...state,
        successstorys: null,
        pages: null,
        page: null,
        total_successstorys: 0,
        successstorys_loading: true,
      };
    case GET_SUCCESSSTORYS:
      return {
        ...state,
        successstorys: payload.success_stories,
        pages: payload.pages,
        page: payload.page,
        total_successstorys: payload.total_success_stories,
      };
    case GET_SUCCESSSTORYS_ENDED:
      return {
        ...state,
        successstorys_loading: false,
      };
    case GET_ALL_SUCCESSSTORYS_STATED:
      return {
        ...state,
        all_successstorys_loading: true,
        all_successstorys: null,
      };
    case GET_ALL_SUCCESSSTORYS:
      return {
        ...state,
        all_successstorys: payload,
      };
    case GET_ALL_SUCCESSSTORYS_ENDED:
      return {
        ...state,
        all_successstorys_loading: false,
      };

    case ADD_SUCCESSSTORY_STATED:
      return {
        ...state,
        successstory_message: null,
        add_successstory_loading: true,
      };
    case ADD_SUCCESSSTORY:
      return {
        ...state,
        successstory_message: payload,
      };
    case ADD_SUCCESSSTORY_ENDED:
      return {
        ...state,
        add_successstory_loading: false,
      };
    case GET_SUCCESSSTORY_STATED:
      return {
        ...state,
        successstory: null,
        successstory_loading: true,
      };
    case GET_SUCCESSSTORY:
      return {
        ...state,
        successstory: payload,
      };
    case GET_SUCCESSSTORY_ENDED:
      return {
        ...state,
        successstory_loading: false,
      };
    case EDIT_SUCCESSSTORY_STATED:
      return {
        ...state,
        successstory_message: null,
        edit_successstory_loading: true,
      };
    case EDIT_SUCCESSSTORY:
      return {
        ...state,
        successstory_message: payload,
      };
    case EDIT_SUCCESSSTORY_ENDED:
      return {
        ...state,
        edit_successstory_loading: false,
      };

    default:
      return state;
  }
};
