import { combineReducers } from 'redux';
import alert from './alert_reducer';
import auth from './auth_reducer';
import { location_reducer } from './location_reducer';
import { brand_reducer } from './brand_reducer';
import { successstory_reducer } from './successstory_reducer';
import { webpage_reducer } from './webpage_reducer';

export default combineReducers({
  alert,
  auth,
  location: location_reducer,
  brand: brand_reducer,
  successstory: successstory_reducer,
  webpage: webpage_reducer,
});
