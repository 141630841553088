import React, { useState } from 'react';
import BreadCrumb from '../../components/template/BreadCrumb';
import Header from '../../components/template/Header';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Spinner from '../../components/layout/Spinner';
import WebpageForm from '../../components/webpages/WebpageForm';
import { convertToFormData } from '../../shared/upload';
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from '../../shared/enums/webpages_enum';
import {
  useUpdateWebpage,
  useSingleWebpage,
  useGetDropdownOptions,
  useCreateWebpage,
} from '../../shared/hooks/UseWebpage';
// import { useSelectAllWebpage } from "../../shared/hooks/UseWebpage";

const DuplicateWebpage = ({ match }) => {
  let history = useHistory();
  const [single_data] = useSingleWebpage(match.params.id);
  const [updateData] = useUpdateWebpage();
  const [webpage_data, addData] = useCreateWebpage();
  const { webpage_loading, webpage, edit_webpage_loading } = single_data;
  const [featuredImage, setFeaturedImage] = useState(null);

  const [dropdownOptions] = useGetDropdownOptions();
  const submitFormClicked = async (values) => {
    await addData(values);
    history.push(`/${LINK_URL}/${webpage._id}/view`);
  };

  return (
    <div className='pace-done'>
      <div>
        <Header />
        <BreadCrumb
          title={`Duplicate ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink='Duplicate'
        />
      </div>
      <div className='container-fluid'>
        <div className='col-lg-12'>
          <div className='card'>
            {!webpage_loading ? (
              webpage && (
                <div>
                  <div className='card-header'>
                    <div>
                      <h4 className='card-title'>
                        {webpage[Object.keys(inputFields)[0]]} -{' '}
                        <span>Duplicate</span>{' '}
                      </h4>
                      <p className='card-title-desc'>
                        <Link
                          to={`/${LINK_URL}`}
                          className='btn btn-soft-light'
                        >
                          <i className='fa fa-angle-left'></i> {PAGE_TITLE}
                        </Link>
                        <Link
                          to={`/${LINK_URL}/${webpage._id}/view`}
                          className='btn btn-soft-light'
                        >
                          <i className='fa fa-eye'></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <WebpageForm
                    data={webpage}
                    edit={true}
                    featuredImage={featuredImage}
                    setFeaturedImage={setFeaturedImage}
                    submitForm={submitFormClicked}
                    inputFields={inputFields}
                    initialValues={initialValues}
                    dropdown_options={dropdownOptions}
                    loading={edit_webpage_loading}
                  />
                </div>
              )
            ) : (
              <div className='text-center'>
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DuplicateWebpage;
