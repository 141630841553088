export const MENU = [
  {
    label: 'Dashboard',
    link: '/dashboard',
    roles: ['ADMIN'],
  },
  {
    label: 'Landing Pages',
    link: '#',
    roles: ['ADMIN'],
    menu: [
      {
        label: 'Locations',
        link: '/locations',
        roles: ['ADMIN'],
      },
      {
        label: 'Brands',
        link: '/brands',
        roles: ['ADMIN'],
      },

      {
        label: 'Success Stories',
        link: '/success-stories',
        roles: ['ADMIN'],
      },
      {
        label: 'Webpage',
        link: '/webpages',
        roles: ['ADMIN'],
      },
    ],
  },
];
