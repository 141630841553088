export const PAGE_TITLE = 'Webpages';
export const PAGE_SINGLE_TITLE = 'Webpage';
export const LINK_URL = 'webpages';
export const inputFields = {
  name: {
    type: 'string',
    required: true,
    title: 'Name',
    inputType: 'text',
    slug: true,
  },
  slug: {
    type: 'slug',
    required: true,
    title: 'Slug',
    inputType: 'text',
  },
  title: {
    type: 'string',
    required: true,
    title: 'Title',
    inputType: 'text',
  },
  gtag: {
    type: 'string',
    required: false,
    title: 'Google Tag',
    inputType: 'text',
  },
  gtag_thank_you: {
    type: 'string',
    required: false,
    title: 'Google Tag Thank You Page',
    inputType: 'text',
  },
  meta_description: {
    type: 'string',
    required: true,
    title: 'Meta Description',
    inputType: 'text',
  },
  location: {
    type: 'related',
    required: true,
    title: 'Location',
    field: 'name',
  },
  banners: {
    type: 'gallery',
    required: false,
    title: 'Banners',
  },
  hero_section_content: {
    type: 'string',
    required: true,
    title: 'Hero Section Content',
    inputType: 'text',
  },
  hero_section_form_title: {
    type: 'string',
    required: true,
    title: 'Hero Section Form  Title',
    inputType: 'text',
  },
  form_fields: {
    title: 'Form Fields',
    type: 'array',
    fields: {
      name: {
        type: 'string',
        required: true,
        title: 'Name',
        inputType: 'text',
      },
      label: {
        type: 'string',
        required: true,
        title: 'Label',
        inputType: 'text',
      },
      type: {
        type: 'string',
        required: true,
        title: 'Type',
        inputType: 'text',
      },
      input_type: {
        type: 'string',
        required: true,
        title: 'Input Type',
        inputType: 'text',
      },
      options: {
        type: 'string',
        required: true,
        title: 'Options',
        inputType: 'text',
      },
    },
  },

  offers: {
    title: 'Offers',
    type: 'array',
    fields: {
      image: {
        type: 'file',
        required: true,
        title: 'Image',
        inputType: 'text',
      },
      heading: {
        type: 'string',
        required: true,
        title: 'Heading',
        inputType: 'text',
      },
      content: {
        type: 'text',
        required: true,
        title: 'Content',
        inputType: 'text',
      },
      button_text: {
        type: 'string',
        required: true,
        title: 'Button Text',
        inputType: 'text',
      },
    },
  },
  details_slider: {
    type: 'gallery',
    required: false,
    title: 'Details Slider',
  },
  before_after: {
    type: 'gallery',
    required: false,
    title: 'Before After',
  },
  why_section_heading: {
    type: 'string',
    required: true,
    title: 'Why Section Heading',
  },
  why_section_content: {
    type: 'html',
    required: true,
    title: 'Why Section Content',
  },
  success_stories: {
    type: 'related',
    required: false,
    title: 'Success Stories',
    multiple: true,
    field: 'name',
  },
};
export const initialValues = {
  name: '',
  form_fields: [],
  offers: [],
};

export const view_all_table = [
  { name: 'Name', value: 'name' },
  { name: 'Location', value: 'location', related: true, field: 'name' },
];

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
];
