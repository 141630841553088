export const PAGE_TITLE = 'Locations';
export const PAGE_SINGLE_TITLE = 'Location';
export const LINK_URL = 'locations';
export const inputFields = {
  name: {
    type: 'string',
    required: true,
    title: 'Name',
    inputType: 'text',
    slug: true,
  },
  email: {
    type: 'string',
    required: true,
    title: 'Email',
    inputType: 'text',
  },
  slug: {
    type: 'slug',
    required: true,
    title: 'Slug',
    inputType: 'text',
  },
  phone: {
    type: 'string',
    required: true,
    title: 'Phone',
    inputType: 'text',
  },
  whatsapp: {
    type: 'string',
    required: true,
    title: 'Whatsapp',
    inputType: 'text',
  },
  address: {
    type: 'string',
    required: true,
    title: 'Address',
    inputType: 'text',
  },
  map: {
    type: 'string',
    required: true,
    title: 'Map',
    inputType: 'text',
  },
};
export const initialValues = {
  name: '',
};

export const view_all_table = [
  { name: 'Name', value: 'name' },
  { name: 'Phone', value: 'phone' },
  { name: 'Whatsapp', value: 'whatsapp' },

  { name: 'Address', value: 'address' },
];

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
];
