export const PAGE_TITLE = 'Success Stories';
export const PAGE_SINGLE_TITLE = 'Success Story';
export const LINK_URL = 'success-stories';
export const inputFields = {
  label: {
    type: 'string',
    required: true,
    title: 'Label',
    inputType: 'text',
  },
  name: {
    type: 'string',
    required: true,
    title: 'Name',
    inputType: 'text',
  },
  image: {
    type: 'file',
    required: true,
    title: 'Image',
  },
  content: {
    type: 'text',
    required: true,
    title: 'Content',
    inputType: 'text',
  },
};
export const initialValues = {
  name: '',
  label: '',
};

export const view_all_table = [
  { name: 'Name', value: 'name' },
  { name: 'Label', value: 'label' },
];

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
];
