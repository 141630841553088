import React from 'react';
import { Link } from 'react-router-dom';
function TableDuplicateBtn({ id, item }) {
  return (
    <Link to={`/${item}/${id}/duplicate`} className='btn btn-soft-light'>
      <i className='fa fa-copy'></i>
    </Link>
  );
}

export default TableDuplicateBtn;
