import { useState, useEffect,useCallback } from "react";
import { useSelector, useDispatch  } from "react-redux";
import {
  addSuccessStory,
  getSuccessStorys,
  getSuccessStory,
  editSuccessStory,
  deleteSuccessStory,
  getAllSuccessStorys,
} from "../../store/actions/successstory_action";
import _debounce from "lodash/debounce";
// import { useSelectAllIndustry } from "./UseIndustry";

// Get All Data
export const useAllSuccessStorys = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.successstory);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
 
  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteSuccessStory(deleteEntry));
  }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
     
      dispatch(
        getSuccessStorys({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [
    data, setPageNumber, deleteBtnClicked
  ];
};

// Get Single Data
export const useSingleSuccessStory = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.successstory);
  useEffect(() => {
    dispatch(getSuccessStory(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateSuccessStory = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.successstory);
  const addData = async (data) => {
    await dispatch(addSuccessStory(data));
  };
  return [data, addData];
};
export const useUpdateSuccessStory = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.successstory);
  const updateData = async (id, data) => {
    await dispatch(editSuccessStory(id, data));
  };
  return [updateData];
};

export const useSelectAllSuccessStory = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.successstory);
  useEffect(() => {
    dispatch(getAllSuccessStorys({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};


export const useGetDropdownOptions = () => {
  //  const [client, setClientSearchField, setClientSearchValue] =
    // useSelectAllClient();
 
  const [dropdownOptions, setDropdownOptions] = useState({});
  // useEffect(() => {
  //   if (industry && industry.all_industrys) {
  //     const newData = industry.all_industrys.map((item) => {
  //       return { label: item.name, value: item.name };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, industry: newData });
  //   }
  // }, [industry]);

 
  return [dropdownOptions
    // setClientSearchField, setClientSearchValue
  ];
};